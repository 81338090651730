import { useState } from 'react'
import axios from 'axios'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'

export default function Popup(props) {
    const { isEnglish } = props

    const specialtyList = [
        isEnglish ? 'Dentist' : 'Dentiste',
        isEnglish ? 'General Practitioner' : "Médecin Généraliste",
        isEnglish ? 'Specialist' : 'Spécialiste',
    ]

    const [specialty, setSpecialty] = useState(specialtyList[0])
    const [email, setEmail] = useState(null)
    const [newsletterSent, setNewsletterSent] = useState(false)

    function sendNewsletter(e) {
        e.preventDefault()

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
        if (!emailRegex.test(email)) {
            alert(isEnglish ? 'Invalid email format' : "Format d'email incorrect")
            return
        }

        const requestBody = { email, specialty, language: isEnglish ? 'EN' : 'FR' }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        axios.post(`https://novamedical-server.onrender.com/api/comms/newsletter`, requestBody, { headers })
            .then(result => {
                if (result.status !== 400) {
                    setNewsletterSent(true)
                    localStorage.setItem('popup', true)
                    setTimeout(() => {
                        props.setOpen(false)
                    }, 1000);
                }
                else {
                    alert(isEnglish ? 'You are already signed up' : 'Vous êtes déjà inscrit')
                }
            })
            .catch(function (error) {
                alert(isEnglish ? "Error signing up to the newsletter. Try again." : "Erreur dans l'inscription à la newsletter. Veuillez réessayer.")
            });

    }

    return (
        <Dialog open={props.open} onClose={props.setOpen} className="fixed z-40">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <div className="fixed top-1/4 sm:inset-0 z-50 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-0 text-center sm:items-center">
                    <DialogPanel
                        transition
                        className="relative transform py-6 sm:py-8 overflow-hidden rounded-lg bg-white px-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-2xl sm:p-12 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <form onSubmit={(e) => sendNewsletter(e)}>
                            <div className="text-center">
                                <DialogTitle as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                                    {isEnglish ? "Hi !" : 'Bonjour !'}
                                    <br />
                                    {isEnglish ? "It's real nice to meet you..." : 'Ravis de vous rencontrer...'}

                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        {isEnglish ?
                                            "You've been thinking about starting a new career in France?"
                                            :
                                            "Vous réfléchissez à une nouvelle carrière en France?"
                                        }<br />
                                        {isEnglish ?
                                            "Get in touch now..."
                                            :
                                            "Contactez-nous maintenant..."
                                        }
                                    </p>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense grid-cols-1 sm:grid-cols-3 sm:gap-3">
                                    <div className='flex items-center justify-center sm:justify-end'>
                                        <p className="text-sm text-gray-900 text-end">
                                            {isEnglish ? "I am a..." : "Je suis un..."}
                                        </p>
                                    </div>
                                    <div className='w-full col-span-2 mt-2 sm:mt-0 sm:pr-16'>
                                        <Listbox value={specialty} onChange={setSpecialty}>
                                            <div className="relative">
                                                <ListboxButton data-autofocus className="w-full relative cursor-default rounded-md bg-white py-2 pl-6 pr-12 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 text-sm sm:leading-6">
                                                    <span className="block truncate">{specialty}</span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                                                    </span>
                                                </ListboxButton>

                                                <ListboxOptions
                                                    transition
                                                    className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in text-sm"
                                                >
                                                    {specialtyList.map((specialty) => (
                                                        <ListboxOption
                                                            key={specialty}
                                                            value={specialty}
                                                            className="group relative cursor-default select-none py-2 pl-6 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                                        >
                                                            <span className="block truncate font-normal group-data-[selected]:font-semibold">{specialty}</span>

                                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                                <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                                            </span>
                                                        </ListboxOption>
                                                    ))}
                                                </ListboxOptions>
                                            </div>
                                        </Listbox>
                                    </div>
                                </div>
                                <div className="mt-3 sm:mt-4 sm:grid sm:grid-flow-row-dense grid-cols-1 sm:grid-cols-3 sm:gap-3">
                                    <div className='flex items-center justify-center sm:justify-end'>
                                        <p className="text-sm text-gray-900 text-end">
                                            {isEnglish ? "My email is..." : "Mon email..."}
                                        </p>
                                    </div>
                                    <div className='w-full col-span-2 mt-2 sm:mt-0 sm:pr-16'>
                                        <input
                                            id="email-address"
                                            name="email-address"
                                            type="email"
                                            required
                                            onChange={e => setEmail(e.target.value)}
                                            placeholder={isEnglish ? "johndoe@gmail.com" : "albertdupont@gmail.com"}
                                            autoComplete="email"
                                            className="w-full appearance-none rounded-md border-0 px-3 py-2 text-base placeholder:text-gray-400 pl-5 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                {!newsletterSent ?
                                    <button
                                        type="submit"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                    >
                                        {isEnglish ? "Let's go!" : "C'est parti!"}
                                    </button>
                                    :
                                    <button
                                        disabled
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                    >
                                        {isEnglish ? 'Success' : "Succès"}
                                        <CheckIcon aria-hidden="true" className="animateIcon ml-1 sm:ml-2 h-5 w-5" />
                                    </button>
                                }
                                <button
                                    type="button"
                                    onClick={() => {
                                        props.setOpen(false)
                                        if (!localStorage.getItem('popupCount')) {
                                            localStorage.setItem('popupCount', 1)
                                        }
                                        else {
                                            localStorage.setItem('popupCount', localStorage.getItem('popupCount') + 1)
                                        }
                                    }}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                >
                                    {isEnglish ? 'Dismiss' : 'Fermer'}
                                </button>
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
