import './TeamFeature.css'

export default function TeamFeature(props) {
    const { isEnglish } = props
    return (
        <div className='bg-white relative sm:mx-12 px-6 sm:px-10 py-12 sm:py-20 rounded-xl shadow-xl'>
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 sm:gap-y-20 sm:px-8 xl:grid-cols-5">
                <div className="max-w-2xl xl:col-span-2">
                    <h4 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">{isEnglish ? "The team" : "L'équipe"}</h4>
                    <p className="mt-2 sm:mt-4 text-md sm:text-lg leading-6 text-gray-600">
                        {isEnglish ?
                            "We’re a dynamic group of individuals who are passionate about what we do and dedicate ourselves to delivering the best care for our patients."
                            :
                            "Nous sommes un groupe de coéquipiers soudés et passionnés par ce que nous faisons et en particulier apporter les meilleurs soins à nos patients."
                        }
                    </p>
                </div>
                <ul role="list" className="space-y-8 sm:space-y-12 divide-y divide-gray-200 xl:col-span-3">
                    {props.people.map((person) => (
                        <li key={person.name} className="flex flex-col gap-4 sm:gap-10 pt-10 sm:pt-12 sm:flex-row">
                            <img alt="" src={person.imageUrl} className="aspect-[4/5] w-36 sm:w-52 flex-none rounded-2xl object-cover" />
                            <div className="max-w-xl flex-auto">
                                <h3 className="text-xl sm:text-2xl font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                <p className="text-md sm:text-lg leading-6 text-gray-600">{person.role}</p>
                                <p className="mt-4 text-sm sm:text-lg leading-6 text-gray-600">{person.bio}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
