import radio from '../assets/radio.jpg'
import attente from '../assets/attente.jpg'
import teamAbout from '../assets/teamAbout.jpg'
import ville from '../assets/ville.jpg'

export default function AboutHero(props) {
    const { isEnglish } = props

    return (
        <div className="overflow-hidden bg-white">
            <div className="mx-auto max-w-7xl lg:flex">
                <div className="mx-auto grid max-w-xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                    <div className="px-6 sm:px-12 lg:col-end-1 lg:w-full lg:max-w-2xl sm:pb-8">
                        <h3 className="aboutText1 text-md sm:text-lg font-semibold leading-7 text-indigo-600">NEOMED</h3>
                        <h2 className="mt-0 sm:mt-2 aboutText2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{isEnglish ? 'A new generation of private clinics in France' : 'Une Nouvelle Génération de Cliniques Privées en France'}</h2>
                        <p className="aboutText3 mt-6 text-sm sm:text-lg leading-7 text-gray-600">
                            {isEnglish ?
                                "Neomed was founded in 2022 with the goal of providing unique opportunities to foreign doctors in France and address the shortage of qualified practitioners in the country. We support our doctors every step of the way, from learning French to discovering the region and starting a new life and career here."
                                :
                                "Neomed a été fondée en 2022 avec l'objectif de créer de nouvelles opportunités pour les médecins européens en France tout en participant à améliorer l'accés aux soins dans notre pays. Nous aidons nos praticiens à toutes les étapes de leur expatriation : de l'apprentissage du français à la découverte de la région, l'installation en France et le début d'activité."
                            }
                        </p>
                        <p className="aboutText4 mt-2 sm:mt-4 text-sm sm:text-lg leading-7 text-gray-600">
                            {isEnglish ?
                                "Our clinics are located in the Center of France, in and around our home base of Chateauroux. 2h away from Paris, the region has a superior quality of life, plenty of patients and strong tax incentives for new practitioners."
                                :
                                "Nos cabinets sont situés dans le Centre de la France, autour de la ville de Châteauroux. A 2h de Paris à peine, la région offre une excellente qualité de vie, des patients nombreux et fidèles ainsi que des avantages fiscaux intéressants pour les nouveaux venus."
                            }
                        </p>
                        <div className="aboutText5 mt-4 flex">
                            <div
                                onClick={() => props.anchorScroll('#contact-form')}
                                className="rounded-md cursor-pointer bg-gray-900 px-3.5 py-2.5 text-sm sm:text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {isEnglish ? 'Join the team  ' : "Rejoignez nous  "}<span aria-hidden="true">&rarr;</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                        <div className="aboutImg1 w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                            <img
                                alt=""
                                src={teamAbout}
                                className="aspect-[7/5] w-[20rem] sm:w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover object-left-bottom"
                            />
                        </div>
                        <div className="aboutImg2 contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                            <div className="order-first -ml-36 sm:ml-0 flex w-64 flex-none justify-end self-end lg:w-auto">
                                <img
                                    alt=""
                                    src={attente}
                                    className="aspect-[4/3] w-[16rem] sm:w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-left-bottom"
                                />
                            </div>
                            <div className="aboutImg3 translate-x-4 sm:translate-x-0 flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                                <img
                                    alt=""
                                    src={radio}
                                    className="aspect-[7/5] w-[20rem] sm:w-[37rem] max-w-none -translate-x-20 sm:translate-x-0 flex-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                            <div className="aboutImg4 hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                                <img
                                    alt=""
                                    src={ville}
                                    style={window.innerWidth < 500 ? { backgroundPosition: 'bottom 0px right -50px' } : {}}
                                    className="aspect-[4/3] w-[14rem] sm:w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
