import React from 'react'

function FeatureBlock(props) {
    return (
        <div className={`featureBlock grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-y-0 gap-x-20 py-8 items-center transform origin-center translate-x-${props.position}`}>
            <img className='rounded-3xl' src={props.img} alt="" />
            <div className='text-start max-w-md'>
                <h3 className='text-3xl font-semibold mb-4'>{props.title}</h3>
                <p className='text-base leading-7 text-gray-800'>
                    {props.text}
                </p>
                <div className='mt-8'>
                    <a
                        href={props.link}
                        className="rounded-md cursor-pointer bg-gray-900 px-8 py-3 text-sm md:text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        {props.button}  <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default FeatureBlock