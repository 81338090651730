import Engine from 'publicodes'
import rules from 'modele-social'

export default function evaluateSalary(brut, parts, married, benefits) {
    const engine = new Engine(rules)

    let net
    if (!benefits) {

        // 1. Go from brut to net imposable
        let netImposableFromBrut = engine
            .setSituation({
                "salarié . contrat": "'CDI'",
                "salarié . contrat . salaire brut": brut,
                "dirigeant": "non"
            })
            .evaluate("salarié . rémunération . net . à payer avant impôt")

        // 2.a. Apply quotient familial
        let netImposableReduced = netImposableFromBrut.nodeValue / parts

        // 3.a. Go from QF-retreated net imposable to net paid
        let netPaidFromNetImposableReduced = engine
            .setSituation({
                "salarié . contrat": "'CDI'",
                "salarié . rémunération . net . à payer avant impôt": netImposableReduced,
                "dirigeant": "non"
            })
            .evaluate("salarié . rémunération . net . payé après impôt")

        // 4.a. Calculate impot applicable
        let impotPaid = (netImposableReduced - netPaidFromNetImposableReduced.nodeValue) * parts

        // 5.a. Calculate net paid after impot
        net = netImposableFromBrut.nodeValue - impotPaid

        let netImposableReducedRef, netPaidFromNetImposableReducedRef, impotPaidRef
        if ((!married && parts > 1) || (married && parts > 2)) {
            let kidsParts = married ? parts - 2 : parts - 1

            // 2.b. Apply parents-only quotient familial
            netImposableReducedRef = parts === 1.5 ? netImposableFromBrut.nodeValue : netImposableFromBrut.nodeValue / 2

            // 3.b. Go from QF-retreated net imposable to net paid
            netPaidFromNetImposableReducedRef = engine
                .setSituation({
                    "salarié . contrat": "'CDI'",
                    "salarié . rémunération . net . à payer avant impôt": netImposableReducedRef,
                    "dirigeant": "non"
                })
                .evaluate("salarié . rémunération . net . payé après impôt")

            // 4.b. Calculate impot paid
            impotPaidRef = (netImposableReducedRef - netPaidFromNetImposableReducedRef.nodeValue) * (parts - kidsParts)

            // 4. Compare the net paid to the net imposable in both cases to assess kids-related monthly savings
            if (impotPaidRef - impotPaid > 293.2 * kidsParts) {
                net = netImposableFromBrut.nodeValue - (impotPaidRef - 293.2 * kidsParts)
            }
        }

    }
    else {
        let netImposableFromBrut = engine
            .setSituation({
                "salarié . contrat": "'CDI'",
                "salarié . contrat . salaire brut": brut,
                "dirigeant": "non"
            })
            .evaluate("salarié . rémunération . net . à payer avant impôt")

        let netImposableReduced = netImposableFromBrut.nodeValue * 0.7 / parts
        let netPaidFromNetImposableReduced = engine
            .setSituation({
                "salarié . contrat": "'CDI'",
                "salarié . rémunération . net . à payer avant impôt": netImposableReduced,
                "dirigeant": "non"
            })
            .evaluate("salarié . rémunération . net . payé après impôt")

        let impotPaid = (netImposableReduced - netPaidFromNetImposableReduced.nodeValue) * parts

        net = netImposableFromBrut.nodeValue - impotPaid

        let netImposableReducedRef, netPaidFromNetImposableReducedRef, impotPaidRef
        if ((!married && parts > 1) || (married && parts > 2)) {
            let kidsParts = married ? parts - 2 : parts - 1

            // 2.b. Apply parents-only quotient familial
            netImposableReducedRef = parts === 1.5 ? netImposableFromBrut.nodeValue * 0.7 : netImposableFromBrut.nodeValue * 0.7 / 2

            // 3.b. Go from QF-retreated net imposable to net paid
            netPaidFromNetImposableReducedRef = engine
                .setSituation({
                    "salarié . contrat": "'CDI'",
                    "salarié . rémunération . net . à payer avant impôt": netImposableReducedRef,
                    "dirigeant": "non"
                })
                .evaluate("salarié . rémunération . net . payé après impôt")

            // 4.b. Calculate impot paid
            impotPaidRef = (netImposableReducedRef - netPaidFromNetImposableReducedRef.nodeValue) * (parts - kidsParts)

            // 4. Compare the net paid to the net imposable in both cases to assess kids-related monthly savings
            if (impotPaidRef - impotPaid > 293.2 * kidsParts) {
                net = netImposableFromBrut.nodeValue - (impotPaidRef - 293.2 * kidsParts)
            }
        }
    }

    return net
}
