import { Switch } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function RevenueTile(props) {
    const buttonRef = useRef(null)
    const { isEnglish } = props

    return (
        <div
            key={props.name}
            ref={props.mobileRef}
            className={`relative overflow-hidden rounded-lg bg-white px-4 ${!props.salary ? typeof props.married === 'boolean' ? 'pb-12 sm:pb-16' : 'pb-8 sm:pb-12' : 'pb-12 sm:pb-16'} pt-5 shadow sm:px-6 sm:pt-6`}
        >
            <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                    <props.icon aria-hidden="true" className="h-6 w-6 text-white" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">{props.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-4 sm:pb-6 sm:pb-7">
                {props.salary && props.loading ?
                    <Skeleton baseColor='#E0E0E0' height='1.5em' width='150px' />
                    :
                    <p className={`text-xl sm:text-2xl ${props.type && 'ring-1 ring-inset px-2 pt-1 pb-0.5'} ${props.type === 'gross' ? 'bg-gray-100 ring-gray-700/10' : props.type === 'net' ? 'bg-yellow-50 ring-yellow-700/10' : props.type === 'netReduced' && 'bg-green-50 ring-green-700/10'} flex justify-center text-center items-center rounded-md font-semibold text-gray-900`}>
                        {props.stat}
                    </p>
                }
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-3 sm:py-4 sm:px-6 flex justify-around items-center">
                    {typeof props.married === 'boolean' &&
                        <div className='flex justify-center items-center'>
                            <p className='text-xs font-semibold mr-2'>{props.married ? isEnglish ? 'Married' : 'Marrié' : isEnglish ? 'Single' : 'Célibataire'}</p>
                            <Switch
                                ref={buttonRef}
                                checked={props.married}
                                onChange={(e) => {
                                    props.setLoading(true)
                                    props.setMarried(!props.married)
                                    buttonRef.current.blur()
                                    props.handleScroller()
                                }}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 data-[checked]:bg-indigo-500"
                            >
                                <span className="sr-only">Use setting</span>
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                            </Switch>
                        </div>
                    }
                    {!props.salary ?
                        <div className='text-center flex w-full flex justify-center items-center'>
                            {typeof props.married === 'boolean' && <p className='text-xs mx-4'><span className='font-bold'>{`${props.value}`}</span>{isEnglish ? ` Child${props.value === 1 ? '' : 'ren'}` : ` Enfant${props.value === 1 ? '' : 's'}`}</p>}
                            <input type="range"
                                className='w-full'
                                min={props.min ? props.min : 0}
                                max={props.max}
                                step={props.step}
                                value={props.value}
                                onMouseDown={() => {
                                    props.setLoading(true)
                                    setTimeout(() => {
                                        props.setLoading(false)
                                    }, 1500)
                                }}
                                onChange={(e) => {
                                    props.setLoading(true)
                                    props.set(e.target.value)
                                }}
                                onTouchEnd={() => {
                                    setTimeout(() => {
                                        props.handleScroller()
                                    }, 750)
                                }}
                            />
                        </div>
                        :
                        <div className='flex items-center justify-center'>
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-black mr-2" />
                            <p className="text-xs sm:text-sm">{isEnglish ?
                                'Salaries are not contractual and for illustrative purposes only'
                                :
                                "Les salaires ne sont pas contractuels et à valeur illustrative seulement"
                            }
                            </p>
                        </div>
                    }
                </div>
            </dd>
        </div>
    )
}
